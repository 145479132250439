import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../../../src/tailwind.css"

const MessagesTable = () => {
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        axios.get('http://141.94.206.201:9090/contacts')
            .then((res) => {
                setMessages(res.data);
                console.log(res.data)
            })
            .catch((error) => {
                console.error('Error fetching messages:', error);
            });
    }, []);

    const handleDelete = (id) => {
        axios.delete(`http://141.94.206.201:9090/contacts/${id}`)
            .then(() => {
                setMessages(messages.filter(message => message.id !== id));
            })
            .catch((error) => {
                console.error('Error deleting message:', error);
            });
    };

    const handleView = (message) => {
        setSelectedMessage(message);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedMessage(null);
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h2 className="text-2xl text-center font-bold mb-4">Messages</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b-2 border-gray-300 text-center">Username</th>
                            <th className="py-2 px-4 border-b-2 border-gray-300 text-center">Email</th>
                            <th className="py-2 px-4 border-b-2 border-gray-300 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {messages.map((message) => (
                            <tr key={message.id} className='min-w-full text-xl '>
                                <td className="py-2 px-4 border-b border-gray-300 text-center">{message.fullName}</td>
                                <td className="py-2 px-4 border-b border-gray-300 text-center">{message.email}</td>
                                <td className="py-2 px-4 border-b border-gray-300 text-center flex">
                                    <button
                                        onClick={() => handleView(message)}
                                        className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
                                    >
                                        View
                                    </button>
                                    <button
                                        onClick={() => handleDelete(message.id)}
                                        className="bg-red-500 text-white px-4 py-2 rounded-md"
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {showModal && selectedMessage && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={closeModal}></div>
                        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                            <div className="px-4 py-5 sm:p-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Message Details</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500"><strong>Username:</strong> {selectedMessage.fullName}</p>
                                    <p className="text-sm text-gray-500"><strong>Email:</strong> {selectedMessage.email}</p>
                                    <p className="text-sm text-gray-500"><strong>Message:</strong> {selectedMessage.message}</p>
                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    onClick={closeModal}
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MessagesTable;
