import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../../../src/tailwind.css"

const Users = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios.get('http://141.94.206.201:9090/users')
            .then((res) => {
                // Assuming the API returns an array of user objects with a 'role' attribute
                const filteredUsers = res.data.filter(user => user.role === 'student');
                setUsers(res.data);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
            });
    }, []);

    const handleRemove = (id) => {
        axios.delete(`http://141.94.206.201:9090/users/${id}`)
            .then((res) => {
                setUsers(users.filter(user => user.id !== id));
            })
            .catch((error) => {
                console.error('Error deleting user:', error);
            });
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h2 className="text-2xl text-center font-bold mb-4">Users</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-gray-200 text-gray-700">
                        <tr>
                            <th className="py-3 px-4 border-b border-gray-300">Username</th>
                            <th className="py-3 px-4 border-b border-gray-300">Email</th>
                            <th className="py-3 px-4 border-b border-gray-300">Actions</th>
                            {/* Add more headers as needed */}
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user._id} className="text-gray-700">
                                <td className="py-3 px-4 border-b border-gray-300">{user.firstName}{user.lastName}</td>
                                <td className="py-3 px-4 border-b border-gray-300">{user.email}</td>
                                <td className="py-3 px-4 border-b border-gray-300">
                                    <button
                                        onClick={() => handleRemove(user.id)}
                                        className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                                    >
                                        Remove
                                    </button>
                                </td>
                                {/* Add more columns as needed */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Users;
