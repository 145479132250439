import React, { useEffect, useState } from 'react';
import './Événements.css';
import Hero from '../Hero/Hero';
import axios from 'axios';
import { useAuth } from '../auth/AuthContext';
import { jwtDecode } from 'jwt-decode'; // Corrected import statement

const Événements = ({ renderHero }) => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [part, setPart] = useState(false);
  const [id, setId] = useState(null);

  const { token } = useAuth();

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.role === "user") {
        setId(decodedToken.userId);
        setPart(true);
      }
    }
  }, [token]);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axios.get('http://141.94.206.201:9090/evennements');
      const filePromises = response.data.map(async (file) => {
        const blobResponse = await axios.get(`http://141.94.206.201:9090/evennements/${file.id}`, { responseType: 'blob' });
        const date = new Date(file.dateheure);
        const formattedDate = date.toLocaleDateString('fr-FR'); // Format the date in French (day-month-year)
        const year = date.getFullYear();
        return {
          ...file,
          year: year,
          formattedDate: formattedDate,
          dateheure: date, // Keep the original date object for sorting
          data: URL.createObjectURL(blobResponse.data)
        };
      });

      let filesWithBlobUrl = await Promise.all(filePromises);

      // Sort the events by date in descending order
      filesWithBlobUrl = filesWithBlobUrl.sort((a, b) => b.dateheure - a.dateheure);

      setEvents(filesWithBlobUrl);

      // Get the unique years from the event data
      const uniqueYears = [...new Set(filesWithBlobUrl.map(event => event.year))];
      setYears(uniqueYears);

      // Set the default filter to the most recent year
      const mostRecentYear = Math.max(...uniqueYears);
      setSelectedYear(mostRecentYear);

      // Filter events for the most recent year
      const filtered = filesWithBlobUrl.filter(event => event.year === mostRecentYear);
      setFilteredEvents(filtered);

    } catch (error) {
      console.error('Error fetching files', error);
    }
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);

    if (year === '') {
      setFilteredEvents(events);
    } else {
      const filtered = events.filter(event => event.year === parseInt(year, 10));
      setFilteredEvents(filtered);
    }
  };

  const participate = (eventId) => {
    axios.post(`http://141.94.206.201:9090/evennements/${eventId}/participate/${id}`)
      .then(res => {
        alert("Your participation was registered");
      })
      .catch(err => {
        alert("Your participation failed");
      });
  };

  return (
    <div className="evenements-page">
      {renderHero && <Hero />}
      <div className="filter-container">
        <select 
          onChange={handleYearChange} 
          value={selectedYear} // Use the selected year for filtering
          className="year-filter"
        >
          <option value="">All Years</option>
          {years
            .slice()  
            .sort((a, b) => b - a)
            .map((year, index) => (
              <option key={index} value={year}>{year}</option>
            ))}
        </select>
      </div>
      <div className='Publications' id='Publications'>
        <div className="events-container">
          {filteredEvents.map((event, index) => (
            <div key={index} className="event-card">
              <h1 className="event-year">{event.formattedDate}</h1>
              <div className="event-details">
                <h2 className="event-title">{event.title}</h2>
                <img src={event.data} alt={`Event ${index + 1}`} className="event-image" />
                <p className="event-description">{event.description}</p>
              </div>
              {part && (
                <button
                  onClick={() => participate(event.id)}
                  style={{ backgroundColor: 'black', color: 'white', width: "50%" }}>
                  Participate
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Événements;
