import React, { useState, useEffect } from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "../../../src/tailwind.css"

// Register the necessary components with Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
   // const [visitorData, setVisitorData] = useState([]);
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);
    const [day, setDay] = useState(null);
    const [hour, setHour] = useState(null);
    const [availableYears, setAvailableYears] = useState([]);
    const [availableMonths, setAvailableMonths] = useState([]);
    const [availableDays, setAvailableDays] = useState([]);
    const [availableHours, setAvailableHours] = useState([]);

    useEffect(() => {
       // fetchData();
    }, [year, month, day, hour]);

   /* const fetchData = async () => {
        try {
            const response = await axios.get('http://141.94.206.201:9090/api/visitors', {
                params: { year, month, day, hour }
            });
            const data = response.data;
            setVisitorData(data);
            processVisitorData(data);
        } catch (error) {
            console.error('Error fetching visitor data', error);
        }
    };*/

    const processVisitorData = (data) => {
        const years = new Set();
        const months = new Set();
        const days = new Set();
        const hours = new Set();

        data.forEach(log => {
            const visitTime = new Date(log.visitTime);
            years.add(visitTime.getFullYear());
            if (year) {
                months.add(visitTime.getMonth() + 1);
            }
            if (month) {
                days.add(visitTime.getDate());
            }
            if (day) {
                hours.add(visitTime.getHours());
            }
        });

        setAvailableYears(Array.from(years));
        setAvailableMonths(Array.from(months));
        setAvailableDays(Array.from(days));
        setAvailableHours(Array.from(hours));
    };

    const handleYearChange = (date) => {
        setYear(date ? date.getFullYear() : null);
        setMonth(null);
        setDay(null);
        setHour(null);
    };
    const handleMonthChange = (date) => {
        setMonth(date ? date.getMonth() + 1 : null);
        setDay(null);
        setHour(null);
    };
    const handleDayChange = (date) => {
        setDay(date ? date.getDate() : null);
        setHour(null);
    };
    const handleHourChange = (event) => setHour(event.target.value);

    // Aggregate visitor data based on the selected filters
    const aggregateData = (data) => {
        const aggregation = {};

        data.forEach(log => {
            const visitTime = new Date(log.visitTime);
            let key;
            if (hour !== null) {
                key = visitTime.toISOString().slice(0, 16); // 'YYYY-MM-DDTHH:MM'
            } else if (day !== null) {
                key = visitTime.toISOString().slice(0, 13); // 'YYYY-MM-DDTHH'
            } else if (month !== null) {
                key = visitTime.toISOString().slice(0, 10); // 'YYYY-MM-DD'
            } else if (year !== null) {
                key = visitTime.toISOString().slice(0, 7); // 'YYYY-MM'
            } else {
                key = visitTime.toISOString().slice(0, 4); // 'YYYY'
            }

            if (aggregation[key]) {
                aggregation[key]++;
            } else {
                aggregation[key] = 1;
            }
        });

        return Object.entries(aggregation).map(([key, count]) => ({ key, count }));
    };

   // const aggregatedData = aggregateData(visitorData);

    // Prepare data for Bar chart
   /* const chartData = {
        labels: aggregatedData.map(entry => entry.key),
        datasets: [
            {
                label: 'Number of Visitors',
                data: aggregatedData.map(entry => entry.count),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
            }
        ]
    };*/

    return (
        <div className="dashboard p-6 bg-gray-100 min-h-screen">
            <h1 className="text-2xl font-bold mb-4">Visitor Statistics</h1>
            <div className="filters flex space-x-4 mb-6">
                <div className="filter flex flex-col">
                    <label className="mb-2">Year</label>
                    <DatePicker
                        selected={year ? new Date(year, 0, 1) : null}
                        onChange={handleYearChange}
                        showYearPicker
                        dateFormat="yyyy"
                        className="p-2 border rounded"
                        includeDates={availableYears.map(y => new Date(y, 0, 1))}
                    />
                </div>
                <div className="filter flex flex-col">
                    <label className="mb-2">Month</label>
                    <DatePicker
                        selected={month ? new Date(year, month - 1, 1) : null}
                        onChange={handleMonthChange}
                        showMonthYearPicker
                        dateFormat="MM/yyyy"
                        className="p-2 border rounded"
                        disabled={!year}
                        includeDates={availableMonths.map(m => new Date(year, m - 1, 1))}
                    />
                </div>
                <div className="filter flex flex-col">
                    <label className="mb-2">Day</label>
                    <DatePicker
                        selected={day ? new Date(year, month - 1, day) : null}
                        onChange={handleDayChange}
                        dateFormat="dd/MM/yyyy"
                        className="p-2 border rounded"
                        disabled={!month}
                        includeDates={availableDays.map(d => new Date(year, month - 1, d))}
                    />
                </div>
                <div className="filter flex flex-col">
                    <label className="mb-2">Hour</label>
                    <select
                        value={hour || ''}
                        onChange={handleHourChange}
                        className="p-2 border rounded"
                        disabled={!day}
                    >
                        <option value="">Select Hour</option>
                        {availableHours.map(h => (
                            <option key={h} value={h}>{h}:00</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
