import React, { useState } from 'react';
import axios from 'axios';

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [step, setStep] = useState(1);

    const handleSendResetCode = async () => {
        try {
            await axios.post('http://141.94.206.201:9090/spring/api/send-reset-code', { email });
            setStep(2);
        } catch (error) {
            console.error('Error sending reset code', error);
        }
    };

    const handleResetPassword = async () => {
        try {
            await axios.post('http://141.94.206.201:9090/spring/api/verify-reset-code', { token, newPassword });
            setStep(3);
        } catch (error) {
            console.error('Error resetting password', error);
        }
    };

    return (
        <div className="forgot-password-page">
            {step === 1 && (
                <>
                    <h2>Mot de passe oublié ?</h2>
                    <label>Email</label>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <button className="reset-password-button" onClick={handleSendResetCode}>Réinitialiser le mot de passe</button>
                </>
            )}
            {step === 2 && (
                <>
                    <h2>Entrez le code de réinitialisation</h2>
                    <label>Code</label>
                    <input type="text" value={token} onChange={(e) => setToken(e.target.value)} required />
                    <label>Nouveau mot de passe</label>
                    <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                    <button className="reset-password-button" onClick={handleResetPassword}>Réinitialiser le mot de passe</button>
                </>
            )}
            {step === 3 && (
                <>
                    <h2>Mot de passe réinitialisé avec succès</h2>
                    <a href="/login">Connexion</a>
                </>
            )}
        </div>
    );
};

export default ForgotPasswordPage;
