import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { useAuth } from '../auth/AuthContext';


const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {login}=useAuth();

  const handleSocialLogin = (url) => {
    window.location.href = url;
  };

  const handleLogin = async () => {
    try {
      const response = await fetch('http://141.94.206.201:9090/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json(); // Wait for JSON parsing
        const token = data.token; // Extract token from response data
        login(token)
        // Login successful, redirect or perform actions accordingly
        navigate('/'); // Example redirect to dashboard page
      } else {
        // Handle login failure, show error message or take appropriate action
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error during login:', error);
      // Handle network errors or other exceptions
    }
  };

  return (
    
    <div className='Publications' id='Publications'>
  
    <div className="login-page">
      <h2 className="login-heading">Bienvenue</h2>
      <div className="social-login">
        <button
          className="facebook-button"
          onClick={() => handleSocialLogin('https://www.facebook.com/login')}
        >
          Se connecter avec Facebook
        </button>
        <button
          className="linkedin-button"
          onClick={() => handleSocialLogin('https://www.linkedin.com/uas/login')}
        >
          Se connecter avec LinkedIn
        </button>
        <button
          className="google-button"
          onClick={() => handleSocialLogin('https://accounts.google.com/signin')}
        >
          Se connecter avec Google
        </button>
      </div>
      <div className="login-form">
        <label>Email</label>
        <input
          type="email"
          placeholder="Entrer votre email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>Mot de passe</label>
        <input
          type="password"
          placeholder="Entrer votre mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="login-submit-button" onClick={handleLogin}>
          Se connecter
        </button>
      </div>
      <p className="forgot-password" onClick={() => navigate('/forgot-password')}>
        Mot de passe oublié ?
      </p>
    </div>
    </div>
  );
};

export default LoginPage;
